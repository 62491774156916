import Vue from 'vue/dist/vue.esm'
import { axiosInstance } from './utils/axios'
import App from './App.vue'
import VueMoment from 'vue-moment'
import store from './store'
import Ionic, { IonicVueRouter } from '@ionic/vue'
import '@ionic/core/css/core.css'
import '@ionic/core/css/ionic.bundle.css'
import './theme/style.scss'

import Pdf from 'vue-pdf'
import { EventBus } from './event-bus'
import PdfModal from './components/PdfModal'
import AnswerButtons from './components/AnswerButtons'
import ClockAnswerButtons from './components/ClockAnswerButtons'
import ClockAnswerClocks from './components/ClockAnswerClocks'
import Header from './library/Header'

import Home from './components/Home'
import Login from './components/Login'
import Disclaimer from './components/Disclaimer'
import Screen from './components/Screen'
import Clock from './components/Clock'
import Protocols from './components/Protocols'
import Scales from './components/Scales'
import AutoLogin from './components/AutoLogin'
import ForgotPassword from './components/ForgotPassword'
import ResetPassword from './components/ResetPassword'

import scalesFab from './components/scales/FAB'

import Tabs from './components/Tabs'
import TimeBar from './components/TimeBar'

import screens from './data/screens'
import clockScreens from './data/clock'

import './registerServiceWorker'
const { detect } = require('detect-browser')

Vue.component('custom-header', Header)
Vue.component('pdf', Pdf)
Vue.component('pdf-modal', PdfModal)
Vue.component('answer-buttons', AnswerButtons)
Vue.component('clock-answer-buttons', ClockAnswerButtons)
Vue.component('clock-answer-clocks', ClockAnswerClocks)

Vue.component('umcg-timebar', TimeBar)
Vue.component('umcg-home', Home)
Vue.component('umcg-screen', Screen)
Vue.component('umcg-clock', Clock)
Vue.component('umcg-protocols', Protocols)
Vue.component('umcg-scales', Scales)

Vue.component('umcg-scales-fab', scalesFab)

Vue.use(Ionic)
Vue.use(IonicVueRouter)
Vue.use(VueMoment)

Object.defineProperty(Vue.prototype, '$env', { value: process.env })
Object.defineProperty(Vue.prototype, '$axios', { value: axiosInstance })
Object.defineProperty(Vue.prototype, '$eventBus', { value: EventBus })

document.addEventListener('deviceready', function () {
  Object.defineProperty(Vue.prototype, '$cordova', { value: window.cordova })
  Object.defineProperty(Vue.prototype, '$statusBar', { value: window.StatusBar })

  window.open = window.cordova.InAppBrowser.open
})

Vue.config.productionTip = false

const routes = [
  { path: '/', name: 'home', component: Tabs },
  { path: '/login', name: 'login', component: Login },
  { path: '/autologin/:token', name: 'auto_login', component: AutoLogin },
  { path: '/forgot_password', name: 'forgot_password', component: ForgotPassword },
  { path: '/reset_password/:token', name: 'reset_password', component: ResetPassword },
  { path: '/disclaimer', name: 'disclaimer', component: Disclaimer },
  { path: '/screen/:id', name: 'screen', component: Tabs },
  { path: '/clock/:id?', name: 'clock', component: Tabs },
  { path: '/protocols', name: 'protocols', component: Tabs },
  { path: '/scales', name: 'scales', component: Tabs },
  { path: '/scales/fab', name: 'scales-fab', component: Tabs }
]

const router = new IonicVueRouter({
  routes
})

window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
})

store.commit('clearClocks')
store.commit('clearRouterHistory')

router.beforeEach((to, from, next) => {
  const IsItABackButton = window.popStateDetected
  window.popStateDetected = false
  if (IsItABackButton) {
    store.commit('removeItemFromRouterHistory', from.path)
  } else {
    store.commit('addItemToRouterHistory', { name: to.name, path: to.path, params: to.params })
  }

  if (!['login', 'auto_login', 'forgot_password', 'reset_password'].includes(to.name) && (typeof store.state.user.id === 'undefined' || store.state.user.id === null)) {
    next({ name: 'login' })
  } else if (to.name === 'login' && typeof store.state.user.id !== 'undefined' && store.state.user.id !== null) {
    next({ name: 'home' })
  } else if (to.name === 'screen' && (typeof store.state.disclaimer.accepted === 'undefined' || store.state.disclaimer.accepted !== true)) {
    next({ name: 'disclaimer' })
  } else {
    next()
  }
})

// eslint-disable-next-line no-new
new Vue({
  data: () => ({
    deferredPrompt: null,
    isIOS: false,
    isAndroid: false,
    isChrome: false,
    isSafari: false,
    browser: null
  }),
  methods: {
    captureEvent () {
      window.addEventListener('beforeinstallprompt', (e) => {
        // ! Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault()
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e
      })
    }
  },
  mounted () {
    const browser = detect()
    this.browser = browser
    this.isSafari = browser && browser.name.toLowerCase() === 'ios'
    this.isChrome = browser && browser.name.toLowerCase() === 'chrome'
    this.isIOS = browser && browser.os.toLowerCase().indexOf('ios')
    this.isAndroid = browser && browser.os.toLowerCase().indexOf('android')
    this.captureEvent()

    let ua = navigator.userAgent.toLowerCase()
    this.isAndroid = ua.indexOf('android') > -1 // && ua.indexOf("mobile");

    this.isIOS = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  },
  router,
  store,
  render: h => h(App)
}).$mount('#ionic')

store.commit('setScreens', screens)
store.commit('setClockScreens', clockScreens)
