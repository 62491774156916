<template>
  <ion-page class="ion-page">
    <custom-header></custom-header>
    <ion-content>
      <h1>Protocollen</h1>
      <p>
        In deze stroke App zijn verkorte versies van de protocollen voor diagnostiek en behandeling van een herseninfarct opgenomen.
        Hieronder zijn de volledige protocollen weergegeven.
      </p>
      <div>
        <ul>
          <li>
            <pdf-modal name='Intraveneuze Trombolyse' attachment='intraveneuze_trombolyse_ivt_bij_herseninfarct.pdf'>Intraveneuze trombolyse</pdf-modal>
          </li>
          <li>
            <pdf-modal name='Intra-arteriële behandeling herseninfarct' attachment='protocol_intra_arteriele_behandeling_herseninfarct.pdf'>Intra-arteriële behandeling</pdf-modal>
          </li>
          <li>
            <pdf-modal name='Diagnostiek en behandeling TIA en infarct' attachment='diagnostiek_en_behandeling_van_tia_en_herseninfarct.pdf'>Diagnostiek en behandeling TIA en infarct</pdf-modal>
          </li>
          <li>
            <pdf-modal name='Intracerebraal hematoom' attachment='medische_procedure_intracerebraal_hematoom.pdf'>Intracerebraal hematoom</pdf-modal>
          </li>
        </ul>
      </div>
      <p>
        NB: de lokale actuele protocollen zijn altijd leidend!
      </p>
    </ion-content>
  </ion-page>
</template>

<script>
export default {
  name: 'protocols'
}
</script>

<style type="scss" scoped>
  li {
    line-height: 30px;
  }
</style>
