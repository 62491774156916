<template>
  <ion-page class="ion-page">
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ name }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="$ionic.modalController.dismiss()">
            <ion-icon color="primary" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div id="pdf-zoom" ref="pdfZoom">
        <div class="pdf-page" v-for="i in numPages" :key="i">
          <pdf @loaded="startZoomEvent" :src="src" :page="i" class="pdf"></pdf>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import Pdf from 'vue-pdf'
import panzoom from 'panzoom'

export default {
  name: 'Pdf',
  props: {
    name: String,
    attachment: String
  },
  data () {
    return {
      src: Pdf.createLoadingTask('attachments/' + this.attachment),
      numPages: 0
    }
  },
  methods: {
    startZoomEvent () {
      panzoom(this.$refs.pdfZoom, {
        maxZoom: 4,
        minZoom: 1,
        bounds: true,
        boundsPadding: 1,
        beforeWheel: function (e) {
          return !e.altKey
        }
      })
    }
  },
  mounted () {
    this.src.promise.then(pdf => {
      this.numPages = pdf.numPages
    })
  }
}
</script>

<style type="scss" scoped>
  ion-button ion-icon {
    font-size: 30px !important;
  }
  ion-content {
    --padding-start: 0;
    --padding-end: 0;
    --overflow: hidden;
  }
  #pdf-zoom {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .pdf-page {
    display: flex;
    border-bottom: 5px solid gray;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .pdf {
    width: 100%;
  }
</style>
