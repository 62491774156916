import { render, staticRenderFns } from "./AnswerButtons.vue?vue&type=template&id=5bd6c830&scoped=true&"
import script from "./AnswerButtons.vue?vue&type=script&lang=js&"
export * from "./AnswerButtons.vue?vue&type=script&lang=js&"
import style0 from "./AnswerButtons.vue?vue&type=style&index=0&id=5bd6c830&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bd6c830",
  null
  
)

export default component.exports