<template>
  <ion-page class="ion-page">
    <custom-header></custom-header>
    <ion-content>
      <h1>Schalen</h1>
      <div>
        <ul>
          <li><a href='#' onclick="window.open('https://www.mdcalc.com/nih-stroke-scale-score-nihss', '_blank'); return false;">NIHSS</a></li>
          <li><a href='#' onclick="window.open('https://www.chadsvasc.org/', '_blank'); return false;">CHA2DS2-VASc / HAS-BLED</a></li>
          <li><a href='#' onclick="window.open('https://www.mdcalc.com/abcd2-score-tia', '_blank'); return false;">ABCDD (TIA)</a></li>
          <li><a href='#' onclick="window.open('https://www.mdcalc.com/intracerebral-hemorrhage-ich-score', '_blank'); return false;">ICH (bloeding)</a></li>
          <li><router-link :to="{ name: 'scales-fab'}">FAB</router-link></li>
          <li><a href='#' onclick="window.open('https://www.mdcalc.com/modified-rankin-scale-neurologic-disability', '_blank'); return false;">Rankin Score</a></li>
          <li><a href='#' onclick="window.open('https://www.mdcalc.com/risk-paradoxical-embolism-rope-score', '_blank'); return false;">ROPE (PFO)</a></li>
          <li><a href='#' onclick="window.open('https://qxmd.com/calculate/calculator_464/phases-score', '_blank'); return false;">Phases</a></li>
          <li><a href='#' onclick="window.open('https://www.sealedenvelope.com/car', '_blank'); return false;">CAR</a></li>
        </ul>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
export default {
  name: 'scales'
}
</script>

<style type="scss" scoped>
  li {
    line-height: 30px;
  }
</style>
