<template>
  <ion-page class="ion-page">
    <ion-tabs>

      <ion-tab tab="home" routes="home">
        <umcg-home />
      </ion-tab>

      <ion-tab tab="clock" routes="clock">
        <umcg-clock />
      </ion-tab>

      <ion-tab tab="screen" routes="screen">
        <umcg-screen />
      </ion-tab>

      <ion-tab tab="protocols" routes="protocols">
        <umcg-protocols />
      </ion-tab>

      <ion-tab tab="scales" routes="scales">
        <umcg-scales />
      </ion-tab>

      <ion-tab tab="scales-fab" routes="scales-fab">
        <umcg-scales-fab />
      </ion-tab>

      <template slot="bottom">
        <ion-tab-bar>

          <ion-tab-button tab="clock" :to="goToTab(['clock'])">
            <ion-icon name="time"></ion-icon>
            <ion-label>Klok</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="home" :to="goToTab(['home','screen'])">
            <ion-icon name="git-network"></ion-icon>
            <ion-label>Beslisboom</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="protocols" :to="goToTab(['protocols'])">
            <ion-icon name="document"></ion-icon>
            <ion-label>Protocollen</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="scales" :to="goToTab(['scales', 'scales-fab'])">
            <ion-icon name="list"></ion-icon>
            <ion-label>Schalen</ion-label>
          </ion-tab-button>

          <ion-tab-button @click="logout" :to="goToTab(['home','screen','clock','protocols','scales','scales-fab'])">
            <ion-icon name="log-out"></ion-icon>
            <ion-label>Uitloggen</ion-label>
          </ion-tab-button>

        </ion-tab-bar>
      </template>
    </ion-tabs>
  </ion-page>
</template>

<script>
export default {
  name: 'home',
  methods: {
    goToTab (routerNameList) {
      const getHistoryFromRouterNameList = this.$store.state.routerHistory.filter(obj => routerNameList.includes(obj.name))
      const lastPage = getHistoryFromRouterNameList.pop()
      if (typeof lastPage === 'undefined') {
        return { name: routerNameList[0] }
      } else {
        return {
          name: lastPage.name,
          params: lastPage.params
        }
      }
    },
    logout () {
      this.$ionic.alertController.dismiss()
      this.$ionic.alertController
        .create({
          header: 'Uitloggen?',
          message: 'Weet je zeker dat je uit wilt loggen?',
          buttons: [
            {
              text: 'Ja, uitloggen',
              handler: () => {
                this.$axios.logout()
                this.$store.commit('clearClocks')
                this.$store.commit('clearRouterHistory')
                this.$router.push({
                  name: 'login'
                })
              }
            },
            {
              text: 'Nee, doorgaan',
              role: 'cancel',
              cssClass: 'secondary'
            }
          ]
        })
        .then(a => a.present())
    }
  }
}
</script>

<style type="scss" scoped>
  ion-tab-button {
    --color: #747475;
    --color-focused: #747475;
    --color-selected: #747475;
  }
</style>
