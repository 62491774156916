<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons @click="$router.go(-1)" slot="start" v-show="$store.state.routerHistory.length > 1 && $route.name !== 'login' && $route.name !== 'disclaimer' && checkIfPreviousPageIsLogin()">
        <ion-button>
          <ion-icon color="primary" name="arrow-back"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title class="ion-text-center">
        <!--<img src="../assets/logo.png" />-->
        Beslishulp herseninfarct
      </ion-title>
      <ion-buttons @click="promptRestartScreen" slot="end" v-show="($route.name === 'screen')">
        <ion-button>
          <ion-icon color="primary" name="refresh"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-buttons @click="promptRestartClock" slot="end" v-show="($route.name === 'clock' && $route.params.id > 1)">
        <ion-button>
          <ion-icon color="primary" name="refresh"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script>
export default {
  name: 'Header',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    checkIfPreviousPageIsLogin () {
      return typeof this.$store.state.routerHistory[this.$store.state.routerHistory.length - 2].name !== 'undefined' && this.$store.state.routerHistory[this.$store.state.routerHistory.length - 2].name !== 'login'
    },
    promptRestartScreen () {
      this.$ionic.alertController.dismiss()
      this.$ionic.alertController
        .create({
          header: 'Vragenlijst herstarten?',
          message: 'Weet je zeker dat je de vragenlijst wilt herstarten?',
          buttons: [
            {
              text: 'Ja, herstarten',
              handler: () => {
                this.$router.push({
                  name: 'home'
                })
              }
            },
            {
              text: 'Nee, doorgaan',
              role: 'cancel',
              cssClass: 'secondary'
            }
          ]
        })
        .then(a => a.present())
    },
    promptRestartClock () {
      this.$ionic.alertController.dismiss()
      this.$ionic.alertController
        .create({
          header: 'Klok herstarten?',
          message: 'Weet je zeker dat je de klok wilt herstarten? De tijd zal verloren gaan.',
          buttons: [
            {
              text: 'Ja, herstarten',
              handler: () => {
                this.$router.push({
                  name: 'clock'
                })
              }
            },
            {
              text: 'Nee, doorgaan',
              role: 'cancel',
              cssClass: 'secondary'
            }
          ]
        })
        .then(a => a.present())
    }
  }
}
</script>

<style type="scss" scoped>
  ion-toolbar {
    --padding-top: 5px;
    --padding-bottom: 5px;
  }
  ion-icon {
    font-size: 30px !important;
  }
  img {
    max-height: 50px;
  }
</style>
